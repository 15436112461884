<template>
  <div>
    <PatientButton @getPatientInfo="getPatientInfo" />
    <van-tabs color="#1691fe" title-active-color="#1691fe" @click="changeTab">
      <van-tab
        v-for="(item, index) in tabOptions"
        :key="index"
        :title="item.label"
        :name="item.value"
      />
    </van-tabs>
    <div v-if="list.length > 0" class="appointmentList">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="title" @click="toInfo(item)">
          {{ item.date }} {{ item.beginTime }}~{{ item.endTime }}
          {{ item.code }}号
          <span :class="[item.status === 1 || item.status === 2 ? '' : 'gray']">
            {{ item.statusName }}
            <van-icon
              v-show="item.status === 1"
              name="arrow"
              style="top: 2px"
            />
          </span>
        </div>
        <div class="list" @click="toInfo(item)">
          <div class="grid-table">
            <div>医生</div>
            <div>
              {{ item.doctorName }}
              <span class="dept">{{ item.deptName }}</span>
            </div>
            <div>诊室地点</div>
            <div>{{ item.address }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-else description="暂无预约信息，请重新查询" />
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'

  import PatientButton from '@/components/PatientButton'
  import { Toast } from 'vant'
  import { getAppointmentsList } from '@/api/his/his'
  export default {
    name: 'Index',
    components: { PatientButton },
    data() {
      return {
        active: '全部',
        list: [],
        search: {
          CardNo: '',
          IDCard: '',
          Name: '',
          BeginTime: '',
          EndTime: '',
          Status: 0,
        },

        tabOptions: [
          {
            value: 0,
            label: '全部',
          },
          {
            value: 1,
            label: '已预约',
          },
          {
            value: 2,
            label: '已完成',
          },
          {
            value: 3,
            label: '已退号',
          },
          {
            value: 4,
            label: '已爽约',
          },
        ],
      }
    },
    async created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      if (userInfo != null) {
        this.search.IDCard = userInfo.card_No
        this.search.CardNo = userInfo.number
        this.search.Name = userInfo.name
        this.search.idType = userInfo.idType
        await this.pageInit()
        await this.fetchData()
      }
    },

    methods: {
      //初始化
      async pageInit() {
        let data = JSON.parse(sessionStorage.getItem('day'))
        this.day = data.day
        this.month = data.month
        this.search.BeginTime = moment(new Date())
          .add(-this.month, 'month')
          .format('yyyy-MM-DD')
        this.search.EndTime = moment(new Date())
          .add(this.day, 'day')
          .format('yyyy-MM-DD')
      },
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getAppointmentsList(this.search)
        this.list = data
        Toast.clear()
      },

      changeTab(name) {
        this.search.Status = name
        this.fetchData()
      },

      async getPatientInfo() {
        let userInfo = JSON.parse(store.getters['user/userInfo'])
        if (userInfo != null) {
          this.search.IDCard = userInfo.card_No
          this.search.Name = userInfo.name
          this.search.CardNo = userInfo.number
          this.search.idType = userInfo.idType
          await this.fetchData()
        }
      },

      toInfo(item) {
        console.log(item)
        if (item.status === 1) {
          this.$router.push({
            path: '/appointmentCancel',
            query: {
              doctorCode: this.$Base64.encode(item.doctorCode),
              doctorName: this.$Base64.encode(item.doctorName),
              deptName: this.$Base64.encode(item.deptName),
              beginTime: this.$Base64.encode(item.beginTime),
              endTime: this.$Base64.encode(item.endTime),
              date: this.$Base64.encode(item.date),
              code: this.$Base64.encode(item.code),
              number: this.$Base64.encode(item.number),
            },
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .van-tabs {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
  }
  .appointmentList {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    .title {
      font-weight: bolder;
      padding: 0.3rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
      color: #1691fe;
      span {
        display: inline-block;
        float: right;
        color: #28a881;
      }
      span.gray {
        color: #acacac;
      }
    }

    .list {
      padding: 15px;
    }
  }

  .grid-table {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    div:nth-child(odd) {
      color: #9e9e9e;
      font-size: 0.4rem;
    }
    .dept {
      color: #646464;
      font-size: 0.4rem;
    }
  }
</style>
